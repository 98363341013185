@value styles: "../../themes/Styles.css";
@value colors: '../../themes/Colors.css';
@value texts, icons, mainBg from colors;

.cont {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  background-color: mainBg;
}

.contInner {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.headerCont {
  display: flex;
  flex-direction: column;
  color: texts;
  font-size: 140%;
  font-weight: 600;
  padding: 20px 0px;
  align-items: center;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon {
  color: icons;
  margin-right: 10px;
}

.bodyCont {
  padding: 40px 20px;
  display: flex;
  margin-bottom: 80px;
  flex-direction: column;
  background-color: white;
}

@media only screen and (min-width: 768px) {
  .contInner {
    width: 600px;
    padding: 0 20px;
  }

  .bodyCont {
    padding: 40px;
  }
  .headerCont {
    display: flex;
    flex-direction: column;
    color: texts;
    font-size: 140%;
    font-weight: 600;
    padding: 20px 0px;
    align-items: center;
  }
}
